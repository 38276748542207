<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <div class="card card-custom card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Detail Level
          </span>
        </h3>
      </div>
      <div class="row px-10" v-if="level != null">
        <div class="col-lg-6">
          <label class="col-form-label">Nama Level</label>
          <p class="text-dark-50">
            {{ level.name || "-" }}
          </p>
        </div>
        <div class="col-lg-6">
          <label class="col-form-label">Tipe</label><br />
          <p class="text-dark-50">Level {{ level.level }}</p>
        </div>
      </div>
      <div class="card-body pt-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="align-self-center">
            <span class="font-weight-bolder text-dark"> Daftar Sub Level </span>
          </div>
          <div class="align-self-center">
            <button
              @click="modalLevel = true"
              class="btn btn-success font-weight-bolder font-size-sm"
            >
              Tambah Sub Level
            </button>
          </div>
        </div>

        <div v-if="isLoading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <template v-else-if="level != null && !isLoading">
          <b-card
            no-body
            class="mb-1"
            v-for="subLevel in level.child"
            :key="subLevel._id"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div class="p-3">
                <div class="d-flex justify-content-between">
                  <div
                    class="align-self-center"
                    block
                    v-b-toggle="'accordion-' + subLevel._id"
                  >
                    <span
                      class="text-dark font-weight-bold text-hover-primary font-size-lg mr-2"
                      >{{ subLevel.name || "-" }}
                    </span>

                    <span class="text-muted">Level {{ subLevel.level }}</span>
                  </div>
                  <div class="d-flex" style="gap: 1rem">
                    <span role="button" @click="updateLevel(subLevel)">
                      <i class="fa fa-edit"></i>
                    </span>
                    <span role="button" @click="deleteLevel(subLevel)">
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + subLevel._id"
              :accordion="'my-accordion-1' + subLevel._id"
              role="tabpanel"
            >
              <div class="p-5">
                <ChildComponent
                  @update-level="updateLevel"
                  @delete-level="deleteLevel"
                  :item="subLevel"
                />
              </div>
            </b-collapse>
          </b-card>
        </template>
      </div>
    </div>
    <b-modal
      :no-close-on-backdrop="true"
      @ok="saveLevel"
      v-model="modalLevel"
      centered
      hide-footer
      scrollable
      :title="form._id ? 'Edit Level' : 'Tambah Level'"
    >
      <validation-observer ref="formLevel">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Nama Level</label>
          <div class="col-lg-12">
            <div>
              <validation-provider
                #default="{ errors }"
                name="nama"
                rules="required"
              >
                <input
                  class="form-control"
                  placeholder="Nama Level"
                  type="text"
                  ref="username"
                  v-model="form.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Tipe Level</label>
          <div class="col-lg-12">
            <div>
              <validation-provider
                #default="{ errors }"
                name="tipe_level"
                rules="required"
              >
                <vue-select
                  :clearable="true"
                  v-model="form.level"
                  class="style-chooser"
                  :reduce="(item) => item.value"
                  :options="optionTipeLevel"
                ></vue-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="form.level != 2">
          <label class="col-xl-3 col-lg-3 col-form-label">Induk Level</label>
          <div class="col-12">
            <div>
              <validation-provider #default="{ errors }" name="induk">
                <vue-select
                  :clearable="false"
                  v-model="form.parent_id"
                  class="style-chooser"
                  :reduce="(item) => item.value"
                  :options="optionLevel"
                ></vue-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="mb-3" v-if="form.level == 3 && !form._id">
          <AksesCheckbox
            v-model="form.auto_generated"
            text="Gunakan Default Child Level"
            css="mt-1"
          />
          <small class="text-muted"
            >secara otomatis akan membuat default value pada supervisor dan
            staff</small
          >
        </div>
        <template v-if="form.auto_generated">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label"
              >Default Level Supervisor</label
            >
            <div class="col-12">
              <div>
                <validation-provider #default="{ errors }" name="induk">
                  <vue-select
                    :clearable="false"
                    taggable
                    multiple
                    v-model="form.supervisor_default"
                    class="style-chooser"
                    :reduce="(item) => item.value"
                    :options="defaultOptionLevelSupervisor"
                  ></vue-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label"
              >Default Level Staff</label
            >
            <div class="col-12">
              <div>
                <validation-provider #default="{ errors }" name="induk">
                  <vue-select
                    taggable
                    multiple
                    :clearable="false"
                    v-model="form.staff_default"
                    class="style-chooser"
                    :reduce="(item) => item.value"
                    :options="defaultOptionLevelStaff"
                  ></vue-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
        </template>

        <div class="d-flex justify-content-end">
          <button
            class="btn btn-secondary"
            type="button"
            @click="modalLevel = false"
          >
            Batal
          </button>
          &nbsp;&nbsp;
          <button
            @click="saveLevel"
            class="btn btn-primary"
            :disabled="loadingSubmitLevel"
          >
            Simpan&nbsp;<b-spinner
              v-if="loadingSubmitLevel"
              small
              label="Spinning"
            ></b-spinner>
          </button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AksesCheckbox from "../hak-akses/components/AksesCheckbox.vue";
import {
  CREATE_REGION,
  UPDATE_REGION,
} from "@/core/services/store/region.module";

import {
  GET_ONE_LEVEL,
  DELETE_LEVEL,
  GET_INDUK_LEVEL,
  UPDATE_LEVEL,
  STORE_LEVEL,
} from "@/core/services/store/level.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import mixin from "@/core/services/Helpers";
// import Multiselect from "vue-multiselect";

import ChildComponent from "./components/ChildComponent.vue";
import { debounce } from "lodash";

const initForm = {
  name: "",
  parent_id: null,
  level: 2,
  auto_generated: false,
  supervisor_default: ["Sales Supervisor", "Supervisor", "Kormin"],
  staff_default: ["Salesman", "Admin Cabang", "Staf"],
};

export default {
  name: "DetailLevel",
  mixins: [mixin],
  components: {
    ChildComponent,
    AksesCheckbox,
    // Multiselect,
  },
  data() {
    return {
      isLoading: false,
      level: null,
      modalLevel: false,
      form: {
        ...initForm,
      },
      optionLevel: [
        {
          value: null,
          label: "Tidak Ada induk",
        },
      ],
      optionTipeLevel: [
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
      ],
      defaultOptionLevelSupervisor: [
        {
          value: "Sales Supervisor",
          label: "Sales Supervisor",
        },
        {
          value: "Supervisor",
          label: "Supervisor",
        },
        {
          value: "Kormin",
          label: "Kormin",
        },
      ],
      defaultOptionLevelStaff: [
        {
          value: "Salesman",
          label: "Salesman",
        },
        {
          value: "Admin Cabang",
          label: "Admin Cabang",
        },
        {
          value: "Staf",
          label: "Staf",
        },
      ],
      allLevel: [],
    };
  },
  watch: {
    "form.level": function (newVal) {
      if (this.allLevel.length > 0 && newVal > 2) {
        this.optionLevel = this.allLevel.filter((x) => x.level == newVal - 1);
        this.optionLevel = this.optionLevel.map((item) => ({
          label: `${item.name} ${
            item.parent_id
              ? this.allLevel.filter((parent) => {
                  var x = JSON.parse(JSON.stringify(parent));
                  return x._id == item.parent_id;
                }).length > 0
                ? `- ${
                    this.allLevel.filter((parent) => {
                      var x = JSON.parse(JSON.stringify(parent));
                      return x._id == item.parent_id;
                    })[0].name
                  }`
                : ""
              : ""
          } ${
            item.level == 4
              ? ` - ${
                  this.allLevel.filter((level3Parent) => {
                    var level4ParentId = this.allLevel.filter((parent) => {
                      var x = JSON.parse(JSON.stringify(parent));
                      return x._id == item.parent_id;
                    })[0].parent_id;
                    var t = JSON.parse(JSON.stringify(level3Parent));
                    return level4ParentId == t._id;
                  })[0].name
                }`
              : ""
          }`,
          value: item._id,
        }));

        if (this.optionLevel.length > 0 && this.form._id === undefined) {
          this.form.parent_id = this.optionLevel[0].value;
        }
      } else {
        this.form.parent_id = this.id;
      }
    },
    modalLevel: function (newVal) {
      if (!newVal) {
        this.form = {
          ...initForm,
        };
        this.form.parent_id = this.id;
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.formSiswa.validate().then((success) => {
        this.isLoading = true;
        if (success) {
          if (this.Id) {
            this.form.body._id = this.currentRegion._id;
          } else {
            this.form.body.vendor_id = this.vendorId;
          }

          const _data = JSON.parse(JSON.stringify(this.form.body));
          this.$store
            .dispatch(this.Id ? UPDATE_REGION : CREATE_REGION, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.Id ? "mengubah" : "membuat"} Region`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/region`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.Id ? "mengubah" : "membuat"} Region`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() {},
    getData() {
      const id = this.id;
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_LEVEL, id)
        .then((data) => {
          this.isLoading = false;
          this.level = data.data;

          this.allLevel = [];
          this.level.child.map((e) => this.collectedAllLevel(e));
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast(response.data.message ?? "terjadi kesalahan", {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteLevel(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus level ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_LEVEL, item._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus level`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus level`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    updateLevel(item) {
      const element = item;
      this.modalLevel = true;
      this.form = element;
    },
    getOptionsInduk: debounce(function (search, loading) {
      if (loading) {
        loading(true);
      }
    }, 300),
    saveLevel() {
      this.$refs.formLevel.validate().then(async (success) => {
        if (success) {
          this.$store
            .dispatch(this.form?._id ? UPDATE_LEVEL : STORE_LEVEL, this.form)
            .then(() => {
              this.modalLevel = false;
              this.$bvToast.toast("berhasil menyimpan", {
                title: `Pemberitahuan`,
                variant: "success",
                solid: true,
              });

              this.getData();
            })
            .catch((response) => {
              this.$bvToast.toast(response.data.message, {
                title: `Pemberitahuan`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    collectedAllLevel(item) {
      this.allLevel.push({
        _id: item._id,
        name: item.name,
        level: item.level,
        parent_id: item.parent_id,
      });

      if (item.child !== undefined) {
        item.child.map((e) => this.collectedAllLevel(e));
        return;
      }
    },
  },
  mounted() {
    if (this.id) {
      this.form.parent_id = this.id;
      this.getData();
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["loadingSubmitLevel"]),
    id() {
      return this.$route.params.id;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },
};
</script>

<style scoped>
.style-chooser >>> .vs__search::placeholder,
.style-chooser >>> .vs__dropdown-toggle,
.style-chooser >>> .vs__dropdown-menu {
  border: 1px solid #e4e6ef;
  font-size: 1rem;
  padding: 0.5rem 0.6rem;
  border-radius: 0.42rem;
}

.style-chooser >>> .vs__clear,
.style-chooser >>> .vs__open-indicator {
  fill: #394066;
  font-size: 0.1rem;
}
</style>
