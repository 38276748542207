<template>
  <div>
    <template v-if="item.child !== undefined">
      <div v-for="child in item.child" :key="child._id">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div class="p-3">
              <div class="d-flex justify-content-between">
                <div
                  class="align-self-center"
                  block
                  v-b-toggle="'accordion-' + child._id"
                >
                  <span
                    class="text-dark font-weight-bold text-hover-primary font-size-lg mr-2"
                    >{{ child.name || "-" }}
                  </span>

                  <span class="text-muted">level {{ child.level }}</span>
                </div>
                <div class="d-flex" style="gap: 1rem">
                  <span @click="updateLevel(child)" role="button">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span @click="deleteLevel(child)" role="button">
                    <i class="fa fa-trash"></i>
                  </span>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + child._id"
            :accordion="'my-accordion-' + child._id"
            role="tabpanel"
          >
            <b-card-body>
              <template v-if="child.child !== undefined">
                <ChildLevel
                  @delete-level="deleteLevel"
                  @update-level="updateLevel"
                  v-if="child.level != 4"
                  :item="child"
                />
                <template v-else>
                  <ul>
                    <li v-for="subLevel in child.child" :key="subLevel._id">
                      <span class="text-dark font-weight-bold font-size-lg mr-2"
                        >{{ subLevel.name || "-" }}
                      </span>
                      <span class="text-muted mr-5"
                        >level {{ subLevel.level }}</span
                      >
                      <span
                        class="navi-link cursor-pointer mr-4"
                        @click="updateLevel(subLevel)"
                      >
                        <span class="navi-icon">
                          <i class="fa fa-edit"></i>
                        </span>
                      </span>
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteLevel(subLevel)"
                      >
                        <span class="navi-icon">
                          <i class="fa fa-trash"></i>
                        </span>
                      </span>
                    </li>
                  </ul>
                </template>
              </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ChildLevel",
  props: {
    item: Object,
  },
  components: {
    ChildLevel: this,
  },
  methods: {
    deleteLevel(item) {
      this.$emit("delete-level", item);
    },
    updateLevel(item) {
      this.$emit("update-level", item);
    },
  },
};
</script>
